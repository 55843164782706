<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-layout row wrap v-for="(item, index) in txn.accountingDetails" v-bind:key="`f-${index}`">
        <v-flex xs12 v-if="item.simple">
          <v-layout row wrap>
            <v-flex xs12><h3>Transaction Details</h3></v-flex>
            <v-flex xs3 pl-1>Contact</v-flex>
            <v-flex xs9
              ><b>{{ item.simple.contact.name }}</b></v-flex
            >

            <v-flex xs3 pl-1>Category</v-flex>
            <v-flex xs9
              ><b>{{ item.simple.category.name }}</b></v-flex
            >

            <v-flex xs12>&nbsp;</v-flex>

            <!--<v-flex xs12><h3>Simple Transaction</h3></v-flex>-->
            <v-flex xs5 pl-1>Amount</v-flex>
            <v-flex xs3 :style="{ color: getColor(txn.amounts[0].value < 0) }">
              <span v-if="newStyle">{{ txn.amounts[0].coin }}</span>
              <b v-else>{{ txn.amounts[0].coin }}</b>
            </v-flex>
            <v-flex xs4 :style="{ color: getColor(txn.amounts[0].value < 0) }">{{ txn.amounts[0].value }}</v-flex>
            <v-flex xs5 pl-1>Cost Basis Exchange Rate</v-flex>
            <v-flex xs3
              ><b>{{ item.simple.costBasis.currency }}</b></v-flex
            >
            <v-flex xs4>{{ item.simple.costBasis.exchangeRate }}</v-flex>

            <v-flex xs5 pl-1>Cost Basis</v-flex>
            <v-flex xs3
              ><b>{{ item.simple.costBasis.currency }}</b></v-flex
            >
            <v-flex xs4>{{ item.simple.costBasis.cost }}</v-flex>

            <!--                        <v-flex xs5 pl-1>Cost Basis Exchange Rate</v-flex>-->
            <!--                        <v-flex xs3><b>{{txn.amounts[0].fiatValue.currency}}</b></v-flex>-->
            <!--                        <v-flex xs4>{{txn.amounts[0].fiatValue.exchangeRate.rate}}</v-flex>-->

            <!--                        <v-flex xs5 pl-1>Cost Basis</v-flex>-->
            <!--                        <v-flex xs3><b>{{txn.amounts[0].fiatValue.currency}}</b></v-flex>-->
            <!--                        <v-flex xs4>{{txn.amounts[0].fiatValue.value}}</v-flex>-->
          </v-layout>
        </v-flex>
        <v-flex xs12 v-if="item.multivalue">
          <v-layout row wrap>
            <!--<v-flex>{{item.transfer}}</v-flex>-->
            <v-flex xs12><h3>Detailed Transaction</h3></v-flex>
            <v-flex xs12 pl-1>{{ item.multivalue.notes }}</v-flex>

            <v-flex xs12>&nbsp;</v-flex>

            <v-flex xs12>
              <li
                style="list-style: none"
                v-for="(l, ind) in item.multivalue.items"
                v-bind:key="'mv-' + index + '-' + ind"
              >
                <v-layout>
                  <v-flex xs3 :set="(contact = getContact(l.contactId))">
                    {{ contact.name }}
                  </v-flex>
                  <v-flex xs9>
                    <ul>
                      <li
                        style="list-style: none"
                        v-for="(li, lineIndex) in l.lines"
                        v-bind:key="'line' + lineIndex + '-' + index + '-' + ind"
                      >
                        <v-layout row wrap>
                          <v-flex xs6 :set="(category = getCategory(li.categoryId))">
                            {{ category.name }}
                          </v-flex>
                          <v-flex xs6 :style="{ color: getColor(bn(li.fiatAmount).lt(0)) }">
                            {{ li.fiatAmount }} ({{ li.sourceValue.amount }} {{ li.coin }})
                          </v-flex>
                        </v-layout>
                      </li>
                    </ul>
                  </v-flex>
                  <!--                        <v-flex xs4>-->
                  <!--                          {{invoice.contact ? invoice.contact.name : ''}}-->
                  <!--                        </v-flex>-->
                  <!--                        <v-flex xs5>-->
                  <!--                          ${{item.invoice.invoices[ind].amount}}-->
                  <!--                        </v-flex>-->
                </v-layout>
              </li>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-if="item.invoice">
          <v-layout row wrap>
            <!--<v-flex>{{item.transfer}}</v-flex>-->
            <v-flex xs12><h3>Invoice Payment</h3></v-flex>
            <v-flex xs12 pl-1>{{ item.invoice.notes }}</v-flex>

            <v-flex xs12>&nbsp;</v-flex>

            <v-flex xs3 pl-1>Invoices</v-flex>
            <v-flex xs9>
              <li
                style="list-style: none"
                v-for="(inv, ind) in item.invoice.invoices"
                v-bind:key="'i-' + index + '-' + ind"
                :set="(invoice = getInvoiceDetails(inv.invoiceId))"
              >
                <v-layout>
                  <v-flex xs3>
                    {{ invoice.title }}
                  </v-flex>
                  <v-flex xs4>
                    {{ invoice.contact ? invoice.contact.name : '' }}
                  </v-flex>
                  <v-flex xs5> ${{ item.invoice.invoices[ind].amount }} </v-flex>
                </v-layout>
              </li>
            </v-flex>
            <v-flex xs9 offset-xs3>
              <v-layout row wrap v-if="item.invoice.fees">
                <v-flex xs3>Fee</v-flex>
                <v-flex xs9>
                  <v-layout
                    row
                    wrap
                    v-for="(f, feeIndex) in item.invoice.fees"
                    v-bind:key="'i-' + index + '-' + ind + '-' + feeIndex"
                  >
                    <v-flex xs5>{{ f.fee.displayValue }} {{ f.fee.coin }}</v-flex>
                    <v-flex xs4>${{ f.cost.cost }}</v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-if="item.bill">Bill Transaction</v-flex>
        <v-flex xs12 v-if="item.transfer">
          <v-layout row wrap>
            <!--<v-flex>{{item.transfer}}</v-flex>-->
            <v-flex xs12><h3>Transfer</h3></v-flex>
            <v-flex xs12 pl-1>{{ item.transfer.notes }}</v-flex>

            <v-flex xs12>&nbsp;</v-flex>

            <v-flex xs5 pl-1>Fee Cost Basis</v-flex>
            <v-flex xs3
              ><b>{{ item.transfer.feeCostBasis.currency }}</b></v-flex
            >
            <v-flex xs4>{{ item.transfer.feeCostBasis.cost }}</v-flex>

            <v-flex xs5 pl-1>Fee Cost Basis Exchange Rate</v-flex>
            <v-flex xs3
              ><b>{{ item.transfer.feeCostBasis.currency }}</b></v-flex
            >
            <v-flex xs4>{{ item.transfer.feeCostBasis.exchangeRate }}</v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-if="item.trade">
          <v-layout row wrap>
            <v-flex xs12><h3>Acquired Assets</h3></v-flex>
            <v-flex xs9>
              <li
                style="list-style: none"
                v-for="(aq, ind) in item.trade.acquiredAssets"
                v-bind:key="'aq-' + index + '-' + ind"
              >
                <v-layout>
                  <v-flex xs12 pl-1 :style="{ color: getColor(false) }">
                    {{ aq.fairMarketValue.cost }} ({{ aq.asset.displayValue }} {{ aq.asset.coin }})
                  </v-flex>
                </v-layout>
              </li>
            </v-flex>

            <!-- Disposed -->
            <v-flex xs12></v-flex>
            <v-flex xs12><h3>Disposed Assets</h3></v-flex>
            <v-flex xs9>
              <li
                style="list-style: none"
                v-for="(aq, ind) in item.trade.disposedAssets"
                v-bind:key="'aq-' + index + '-' + ind"
              >
                <v-layout>
                  <v-flex xs12 pl-1 :style="{ color: getColor(true) }">
                    {{ aq.fairMarketValue.cost }} ({{ aq.asset.displayValue }} {{ aq.asset.coin }})
                  </v-flex>
                </v-layout>
              </li>
            </v-flex>

            <!-- Fee -->
            <v-flex xs12></v-flex>
            <v-flex xs12 :set="(contact = getContact(item.trade.tradeFee.feeContactId))"
              ><h3>Fee ({{ contact.name }})</h3></v-flex
            >
            <v-flex xs9>
              <li
                style="list-style: none"
                v-for="(aq, ind) in item.trade.tradeFee.assets"
                v-bind:key="'aq-' + index + '-' + ind"
              >
                <v-layout>
                  <v-flex xs12 pl-1 :style="{ color: getColor(true) }">
                    {{ aq.fairMarketValue.cost }} ({{ aq.asset.displayValue }} {{ aq.asset.coin }})
                  </v-flex>
                </v-layout>
              </li>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-if="item.staking">
          <v-layout row wrap>
            <v-flex xs12
              ><h3>
                {{ item.staking.stakingTxnType === 'Staking' ? $tc('_staking') : $tc('_returningStake') }}
              </h3></v-flex
            >
            <v-flex xs3 pl-1>Contact</v-flex>
            <v-flex xs9
              ><b>{{ item.staking.contact.name }}</b></v-flex
            >

            <v-flex xs3 pl-1>Category</v-flex>
            <v-flex xs9
              ><b>{{ item.staking.category.name }}</b></v-flex
            >

            <v-flex xs12>&nbsp;</v-flex>

            <!--<v-flex xs12><h3>Simple Transaction</h3></v-flex>-->
            <v-flex xs5 pl-1>Amount</v-flex>
            <v-flex xs3 :style="{ color: getColor(txn.amounts[0].value < 0) }">
              <span v-if="newStyle">{{ txn.amounts[0].coin }}</span>
              <b v-else>{{ txn.amounts[0].coin }}</b>
            </v-flex>
            <v-flex xs4 :style="{ color: getColor(txn.amounts[0].value < 0) }">{{ txn.amounts[0].value }}</v-flex>

            <v-flex xs5 pl-1>Cost Basis Exchange Rate</v-flex>
            <v-flex xs3
              ><b>{{ txn.amounts[0].fiatValue.currency }}</b></v-flex
            >
            <v-flex xs4>{{ txn.amounts[0].fiatValue.exchangeRate.rate }}</v-flex>

            <v-flex xs5 pl-1>Cost Basis</v-flex>
            <v-flex xs3
              ><b>{{ txn.amounts[0].fiatValue.currency }}</b></v-flex
            >
            <v-flex xs4>{{ txn.amounts[0].fiatValue.value }}</v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-if="item.assetStaking">
          <v-layout row wrap>
            <v-flex xs12
              ><h3>{{ getStakingTypeName(item.assetStaking.type) }}</h3></v-flex
            >
            <!--                  <v-flex xs3 pl-1>Fee Contact</v-flex>-->
            <!--                  <v-flex xs9><b>{{item.assetStaking.feeContact.name}}</b></v-flex>-->

            <v-flex xs12>&nbsp;</v-flex>

            <!--<v-flex xs12><h3>Simple Transaction</h3></v-flex>-->
            <v-flex xs5 pl-1>Amount</v-flex>
            <v-flex xs3 :style="{ color: getColor(txn.amounts[0].value < 0) }">
              <span v-if="newStyle">{{ txn.amounts[0].coin }}</span>
              <b v-else>{{ txn.amounts[0].coin }}</b>
            </v-flex>
            <v-flex xs4 :style="{ color: getColor(txn.amounts[0].value < 0) }">{{ txn.amounts[0].value }}</v-flex>

            <v-flex xs12 v-if="item.assetStaking.fee">
              <v-layout row wrap>
                <v-flex xs5 pl-1>Fees</v-flex>
                <v-flex xs7>
                  <v-layout row wrap v-for="(fee, i) in item.assetStaking.fee.feeItems" v-bind:key="`as-f-${i}`">
                    <v-flex xs5 :style="{ color: getColor(bn(fee.amount.value).gt(0)) }">
                      <span v-if="newStyle">{{ fee.amount.symbol }}</span>
                      <b v-else>{{ fee.amount.symbol }}</b>
                    </v-flex>
                    <v-flex xs7 :style="{ color: getColor(bn(fee.amount.value).gt(0)) }"
                      >{{ bn(fee.amount.value).mul(-1).toNumber() }} ({{ sym() }}{{ fee.costBasis.cost }})</v-flex
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-if="item.sell">Sell For Fiat Transaction</v-flex>
        <!--              <v-flex>{{item}}</v-flex>-->
      </v-layout>
    </v-flex>
    <!--<v-flex xs12>-->
    <!--{{txn}}-->
    <!--</v-flex>-->
  </v-layout>
</template>
<script>
export default {
  props: ['txn', 'invoices', 'contacts', 'categories', 'newStyle'],
  computed: {
    invoiceMap() {
      const byId = this.invoices.map((m) => [m.id, m]);
      return new Map(byId);
    },
    contactMap() {
      const byId = this.contacts.map((m) => [m.id, m]);
      return new Map(byId);
    },
    categoryMap() {
      const byId = this.categories.map((m) => [m.id, m]);
      return new Map(byId);
    },
  },
  methods: {
    getInvoiceDetails(invoiceId) {
      return this.invoiceMap.get(invoiceId);
    },
    getStakingTypeName(stakingType) {
      switch (stakingType) {
        case 'Stake':
          return 'Staking Tokens';
        case 'Unstake':
          return 'Unstaking Tokens';
        case 'LiquidityPoolAdd':
          return 'Adding to Liquidity Pool';
        case 'LiquidityPoolRemove':
          return 'Removing from Liquidity Pool';
        default:
          return 'Unknown';
      }
    },
    getContact(contactId) {
      return this.contactMap.get(contactId);
    },
    getCategory(categoryId) {
      return this.categoryMap.get(categoryId);
    },
    getColor(cond) {
      if (cond) {
        if (this.newStyle) {
          return 'rgb(255, 108, 119)';
        } else {
          return 'red';
        }
      } else {
        if (this.newStyle) {
          return 'rgb(58, 192, 143)';
        } else {
          return 'green';
        }
      }
    },
  },
};
</script>
