<template>
  <div>
    <ReconciliationNew v-if="isNew" />
    <Reconciliation v-else />
  </div>
</template>
<script>
import Reconciliation from '../../components/accounting/Reconciliation.vue';
import ReconciliationNew from '../../components/accounting/Reconciliation2.vue';

export default {
  components: {
    Reconciliation,
    ReconciliationNew,
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
  computed: {
    isNew() {
      const features = this.$store.getters.features;
      return features['new-transactions-ui'] === 'true';
    },
  },
};
</script>
