var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-font-sans"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium tw-font-heading"},[_vm._v(" "+_vm._s(_vm.$t('Reconcile'))+" ")]),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-ml-3 tw-w-96"},[_c('ui-text-edit',{attrs:{"name":"search","icon":"fa-solid fa-magnifying-glass"},model:{value:(_vm.searchToken),callback:function ($$v) {_vm.searchToken=$$v},expression:"searchToken"}})],1),_c('ui-button',{staticClass:"tw-ml-2",attrs:{"color":"white"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.refresh.apply(null, arguments)}}},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-1 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-rotate"}}),_vm._v(" Refresh ")],1)],1)]),_c('div',{staticClass:"tw-font-sans"},[_c('div',{staticClass:"tw-w-full tw-border-t tw-border-b tw-border-gray-300 tw-py-2"},[_c('div',{staticClass:"tw-pl-4 tw-text-gray-800 tw-flex tw-items-center tw-justify-between tw-relative tw-pr-4"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-h-20 tw-w-full"},[_c('div',[_c('ui-select',{staticClass:"tw-w-80",attrs:{"label":"Wallet Name","values":Object.assign({}, {'All Wallets': 'All'},
                  (_vm.wallets || []).reduce(function (a, x) {
                        var _obj;

                        return (Object.assign({}, a, ( _obj = {}, _obj[x.name] = x.id, _obj )));
}, {}))},model:{value:(_vm.vars.transactionFilter.walletFilter),callback:function ($$v) {_vm.$set(_vm.vars.transactionFilter, "walletFilter", $$v)},expression:"vars.transactionFilter.walletFilter"}})],1)])]),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-4"},[_c('a',{staticClass:"tw-whitespace-nowrap tw-text-sm tw-font-medium tw-flex tw-items-center",class:{
              'tw-text-neutral-300 tw-cursor-default': _vm.transactions ? !_vm.transactions.olderPageToken : false,
            },on:{"click":function($event){_vm.vars.paginationToken = _vm.transactions ? _vm.transactions.olderPageToken || _vm.vars.paginationToken : undefined}}},[_c('fa',{staticClass:"tw-mr-1",attrs:{"icon":"fa-solid fa-caret-left"}}),_vm._v("Earlier")],1),_c('span',{staticClass:"tw-whitespace-nowrap tw-text-sm tw-mr-1 tw-font-medium"},[_vm._v("Jump to ")]),_c('ui-date-picker',{model:{value:(_vm.vars.transactionFilter.pivotDate),callback:function ($$v) {_vm.$set(_vm.vars.transactionFilter, "pivotDate", $$v)},expression:"vars.transactionFilter.pivotDate"}}),_c('span',{staticClass:"tw-whitespace-nowrap tw-text-sm tw-mr-1 tw-font-medium"},[_vm._v("Number of results ")]),_c('div',{},[_c('ui-select',{attrs:{"values":['10', '20', '50', '100'],"align":"right"},model:{value:(_vm.vars.limit),callback:function ($$v) {_vm.$set(_vm.vars, "limit", $$v)},expression:"vars.limit"}})],1),_c('a',{staticClass:"tw-whitespace-nowrap tw-text-sm tw-font-medium tw-flex tw-items-center",class:{
              'tw-text-neutral-300 tw-cursor-default': _vm.transactions ? !_vm.transactions.newerPageToken : false,
            },on:{"click":function($event){_vm.vars.paginationToken = _vm.transactions ? _vm.transactions.newerPageToken || _vm.vars.paginationToken : undefined}}},[_vm._v("Later "),_c('fa',{staticClass:"tw-ml-1",attrs:{"icon":"fa-solid fa-caret-right"}})],1)],1)]),_c('div',{staticClass:"tw-flex tw-justify-end tw-items-center tw-px-4 tw-gap-4"},[(_vm.checkFeatureFlag('force-reconcile-emergency', _vm.features))?_c('UiCheckbox',{attrs:{"label":"Force"},model:{value:(_vm.force),callback:function ($$v) {_vm.force=$$v},expression:"force"}}):_vm._e(),(_vm.checkScope(_vm.scopeLiterals.TransactionReconcileUpdate))?_c('ui-button',{staticClass:"tw-align-middle tw-h-9 tw-w-50 tw-justify-center tw-uppercase",attrs:{"loading":_vm.isSaving,"color":"primary","disabled":_vm.isSaving || _vm.selectedTxns.length === 0},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.clickReconcile.apply(null, arguments)}}},[_vm._v(" Reconcile ")]):_vm._e()],1)]),_c('ui-data-table',{attrs:{"headers":_vm.headers,"isLoading":!!_vm.isLoading,"items":_vm.displayTxns,"actions":[
        {
          label: 'Reconcile',
          value: _vm.reconcileTransactions,
          disabled: !_vm.selectedTxns.length,
        } ],"noDataMessage":"There are no transactions to display.","hideable-columns":"","resizeable-columns":"","checkable-rows":""},on:{"action":_vm.handleActionOnTable,"selection-changed":_vm.onSelectionChanged},scopedSlots:_vm._u([{key:"td-id",fn:function(ref){
      var txn = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_c('a',{staticClass:"tw-overflow-ellipsis tw-inline-block tw-overflow-hidden tw-max-w-xs tw-align-middle"},[_vm._v(" "+_vm._s(txn.id)+" ")]),_c('ui-tooltip',{staticClass:"tw-align-middle tw-ml-2",attrs:{"text":"Copy to clipboard","position":"right"}},[_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(txn.id),expression:"txn.id",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(function () { return _vm.showSuccessSnackbar(_vm.$t('_clipboardSuccess')); }),expression:"() => showSuccessSnackbar($t('_clipboardSuccess'))",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(function () { return _vm.showErrorSnackbar(_vm.$t('_clipboardFail')); }),expression:"() => showErrorSnackbar($t('_clipboardFail'))",arg:"error"}],staticClass:"fa fa-clipboard hover:tw-text-neutral-500 tw-text-neutral-300 tw-cursor-pointer"})])],1)])]}},{key:"td-date",fn:function(ref){
      var txn = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(new Date(txn.created * 1000).toLocaleString(undefined, { timeZoneName: 'short' }))+" ")])]}},{key:"td-wallet",fn:function(ref){
      var txn = ref.item;
return [_c('td',[_c('div',{staticClass:"text-xs-left"},[(txn.wallet && txn.wallet.length > 0)?_c('ul',{staticClass:"pa-0",staticStyle:{"list-style":"none"}},_vm._l((txn.wallet),function(item,index){return _c('li',{key:("w-" + index)},[_vm._v(" "+_vm._s(item && item.name ? item.name : 'Unknown Wallet')+" ")])}),0):_c('div',[_vm._v("Unknown Wallet")])])])]}},{key:"td-amount",fn:function(ref){
      var txn = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-relative"},_vm._l((txn.fullAmountSet),function(amt,i){return _c('div',{key:i,staticClass:"tw-space-x-1 tw-flex tw-whitespace-nowrap",class:{ 'tw-text-success-300': amt.value >= 0, 'tw-text-error-300': amt.value < 0 }},[_c('span',{staticClass:"tw-flex-1 tw-text-left"},[_vm._v(_vm._s(amt.unit))]),_c('span',{staticClass:"tw-flex-1 tw-text-right"},[_vm._v(_vm._s(_vm.toRounded(amt.value, 8)))])])}),0)])]}},{key:"td-account-details",fn:function(ref){
      var txn = ref.item;
return [_c('td',[_c('transaction-accounting-small-details',{attrs:{"txn":txn,"categories":_vm.categories,"contacts":_vm.contacts,"invoices":_vm.invoices,"newStyle":true}})],1)]}},{key:"td-action",fn:function(){return [_c('td')]},proxy:true}])})],1),(_vm.forceCheck)?_c('ui-modal',{on:{"close":function($event){_vm.forceCheck = false;
      _vm.force = false;}}},[_c('div',{staticClass:"sm:tw-flex sm:tw-items-center delete-modal"},[_c('div',{staticClass:"tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left"},[_c('h3',{staticClass:"tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900",attrs:{"as":"h3"}},[_vm._v("Are you absolutely sure?")])])]),_c('div',{staticClass:"tw-mt-8 tw-flex tw-flex-col tw-items-start"},[_c('div',[_c('p',{staticClass:"tw-text-md tw-text-gray-500"},[_vm._v(" Please ensure that these transactions are NOT already posted to your ERP. "),_c('br'),_vm._v(" Clicking this button will create DUPLICATE entries if the entries already exist. "),_c('br'),_vm._v(" DO you want to Continue? ")])])]),_c('div',{staticClass:"tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse"},[_c('button',{staticClass:"tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-bg-red-600 tw-px-4 tw-py-2 tw-text-base tw-font-medium tw-text-white tw-shadow-sm hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm",attrs:{"type":"button"},on:{"click":_vm.reconcileTransactions}},[_vm._v(" Yes, Reconcile ")]),_c('button',{staticClass:"tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-base tw-font-medium tw-text-gray-700 tw-shadow-sm hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm",attrs:{"type":"button"},on:{"click":function($event){_vm.force = false;
          _vm.forceCheck = false;}}},[_vm._v(" No, Cancel ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }