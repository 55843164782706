

















































































































































































































import { faRoadCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { ReconciliationResult, Transaction, TransactionsResult } from '@/api-svc-types';
import { isDefined } from '@/utils/guards';

import { BaseVue } from '../../BaseVue';
import {
  CategoriesQuery,
  ContactsQuery,
  FilteredTransactionsQuery,
  InvoicesQuery,
  WalletsQuery,
} from '../../queries/transactionsPageQuery';
import TransactionAccountingSmallDetails from '../transactions/TransactionAccountingSmallDetails.vue';
import UiButton from '../ui/UiButton.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';
import UiDataTable from '../ui/UiDataTable.vue';
import UiDatePicker from '../ui/UiDatePicker.vue';
import UiLoading from '../ui/UiLoading.vue';
import UiModal from '../ui/UiModal.vue';
import UiRadioGroup from '../ui/UiRadioGroup.vue';
import UiSelect from '../ui/UiSelect.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';
import UiTooltip from '../ui/UiTooltip.vue';

@Component({
  components: {
    UiSelect,
    UiLoading,
    UiTooltip,
    UiButton,
    UiRadioGroup,
    UiTextEdit,
    UiDataTable,
    UiDatePicker,
    TransactionAccountingSmallDetails,
    UiCheckbox,
    UiModal,
  },
  apollo: {
    transactions: {
      query: FilteredTransactionsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            ...this.vars,
            orgId: this.$store.state.currentOrg.id,
            limit: typeof this.vars.limit === 'string' ? Number(this.vars.limit) : this.vars.limit,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoading',
      deep: true,
    },
    invoices: {
      query: InvoicesQuery,
      pollInterval: 20000,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      fetchPolicy: 'network-only',
      loadingKey: 'isLoading',
    },
    categories: {
      query: CategoriesQuery,
      pollInterval: 20000,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      fetchPolicy: 'network-only',
      loadingKey: 'isLoading',
    },
    contacts: {
      query: ContactsQuery,
      pollInterval: 20000,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      fetchPolicy: 'network-only',
      loadingKey: 'isLoading',
    },
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoading',
    },
  },
})
export default class Reconciliation2 extends BaseVue {
  declare transactions?: TransactionsResult;

  public headers = [
    { id: 'id', label: this.$t('_id'), defaultVisibility: true },
    { id: 'date', label: this.$t('_date'), defaultVisibility: true },
    { id: 'wallet', label: this.$tc('_wallet', 2), defaultVisibility: true },
    { id: 'amount', label: this.$t('_amount'), defaultVisibility: true },
    { id: 'account-details', label: this.$t('_accountingDetails'), defaultVisibility: true },
  ];

  public isSaving = false;
  public isLoading = 0;
  public force = false;
  public forceCheck = false;
  public showFilters = true;
  public searchToken = '';
  public vars = {
    transactionFilter: {
      walletFilter: 'All',
      categorizationFilter: 'Categorized',
      reconciliationFilter: 'Unreconciled',
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
      accountingDetails: this.$store.state.currentOrg.taxConfig.capitalizeTradingFees
        ? {
            isTrade: false,
          }
        : undefined,
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public get displayTxns() {
    return this.isLoading ? [] : (this.transactions?.txns ?? []).filter(isDefined);
  }

  public selectedTxns: Transaction[] = [];

  public get selectedTxnsSet(): Set<Transaction> {
    return new Set(this.selectedTxns);
  }

  public onSelectionChanged(txns: Transaction[]) {
    this.selectedTxns = txns;
  }

  public getCurrencySymbol(coin: string) {
    if (coin === 'BTC') {
      return '฿';
    } else if (coin === 'ETH') {
      return 'Ξ';
    } else if (coin === 'EOS') {
      return 'EOS';
    } else {
      return coin;
    }
  }

  public toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }

  public clickReconcile() {
    if (this.force) {
      this.forceCheck = true;
    } else {
      this.reconcileTransactions();
    }
  }

  public reconcileTransactions() {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      transactionIds: this.selectedTxns.map((m: Transaction) => m.id),
      forceReconcile: this.force,
    };
    this.isSaving = true;
    this.$apollo
      .mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $transactionIds: [String]!, $forceReconcile: Boolean) {
            reconcileTransactions(orgId: $orgId, transactionIds: $transactionIds, forceReconcile: $forceReconcile) {
              succeeded
              errors
            }
          }
        `,
        // Parameters
        variables: vars,
      })
      .then((res) => {
        const erroredTxns = res.data.reconcileTransactions.filter((m: ReconciliationResult) => {
          return m.errors && m.errors.length > 0;
        });

        if ((res.errors && res.errors.length > 0) || erroredTxns.length > 0) {
          const errors = [];
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }

          if (erroredTxns && erroredTxns.length > 0) {
            for (const e of erroredTxns) {
              errors.push(...e.errors);
            }
          }

          this.showErrorSnackbar(
            this.$tc('_reconciliationFailure', this.selectedTxns.length) +
              ': ' +
              JSON.stringify(res.data.reconcileTransactions[0].errors)
          );
        } else if (res.data.reconcileTransactions.length > 0 && res.data.reconcileTransactions[0].succeeded) {
          this.showSuccessSnackbar(this.$tc('_reconciliationSuccess', this.selectedTxns.length));
        } else {
          this.showErrorSnackbar(this.$tc('_reconciliationFailure', this.selectedTxns.length));
        }
        this.selectedTxns.splice(0, this.selectedTxns.length);
        this.force = false;
        this.forceCheck = false;
        this.isSaving = false;
        this.$apollo.queries.transactions.refresh();
      });
  }

  public async refresh() {
    this.$apollo.queries.transactions.refetch();
  }

  handleActionOnTable(handler: string | unknown) {
    if (typeof handler !== 'string') {
      (handler as () => void)();
    }
  }

  private searchDebounceTimer?: number;
  @Watch('searchToken')
  public onSearchTokenChange() {
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
    this.searchDebounceTimer = window.setTimeout(() => {
      this.searchDebounceTimer = undefined;
      this.vars.transactionFilter.searchTokens =
        this.searchToken.trim() !== '' ? this.searchToken.split(' ') : undefined;
    }, 1000);
  }
}
